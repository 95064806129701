<template>
	<VFormDialog
		:label="$t('titles.new') + ' ' + $t('characters.name')"
		:signal="signal"
		:pre-complete-ok="preComplete"
		@close="close"
		@cancel="cancel"
		@ok="ok"
		@open="open"
	>
		<VTextFieldWithValidation
			ref="name"
			v-model="name"
			rules="required|min:3|max:30|"
			vid="name"
			:label="$t('forms.name')"
			:counter="30"
		/>
		<VSelectWithValidation
			ref="gameSystem"
			v-model="gameSystemId"
			rules="required"
			vid="gameSystem"
			:items="gameSystems"
			:label="$t('forms.gameSystem')"
		/>
		<VNumberFieldWithValidation
			ref="number"
			v-model="number"
			rules="required|numeric|min_value:1|max_value:99|"
			vid="number"
			:label="$t('forms.number')"
			step="1"
			:counter="2"
		/>
	</VFormDialog>
</template>

<script>
import Constants from '@/constants';

import GlobalUtility from '@thzero/library_client/utility/global';
import LibraryUtility from '@thzero/library_common/utility';

import VFormDialog from '@/library_vue_vuetify/components/form/VFormDialog';
import VNumberFieldWithValidation from '@/library_vue_vuetify/components/form/VNumberFieldWithValidation';
import VSelectWithValidation from '@/library_vue_vuetify/components/form/VSelectWithValidation';
import VTextFieldWithValidation from '@/library_vue_vuetify/components/form/VTextFieldWithValidation';

export default {
	name: 'CharacterNewDialog',
	components: {
		VFormDialog,
		VNumberFieldWithValidation,
		VSelectWithValidation,
		VTextFieldWithValidation
	},
	extends: VFormDialog,
	data: () => ({
		gameSystemId: null,
		name: '',
		number: null,
		serviceCharacters: null
	}),
	computed: {
		gameSystems() {
			const results = GlobalUtility.$store.state.gameSystems;
			return results ? results.filter(l => l.active) : [];
		}
	},
	watch: {
		async gameSystemId(newValue) {
			if (newValue) {
				const response = await this.serviceCharacters.fetchNumber(this.correlationId(), this.gameSystemId);
				this.number = response && response.success ? response.results : null;
				return;
			}

			this.number = null;
		}
	},
	created() {
		this.serviceCharacters = GlobalUtility.$injector.getService(Constants.InjectorKeys.SERVICE_CHARACTERS);
	},
	methods: {
		async close() {
		},
		async cancel() {
			this.$emit('cancel');
		},
		async ok() {
			this.$emit('ok');
			return true;
		},
		async open() {
			this.fieldType = null;
			this.name = '';
		},
		async preComplete(correlationId) {
			const name = String.trim(this.name);
			const number = String.trim(this.number + '');
			const response = await GlobalUtility.$store.dispatcher.characters.createCharacter(correlationId, { gameSystemId: this.gameSystemId, name: name, number: number });
			this.logger.debug('CharacterNewDialog', 'preComplete', 'response', response, correlationId);
			if (this.hasSucceeded(response)) {
				GlobalUtility.$navRouter.push(LibraryUtility.formatUrl({ url: '/character', params: [ response.results.id ]}));
				return response;
			}

			return response;
		},
		async resetDialog() {
			this.gameSystemId = null;
			this.name = '';
			this.number = null;
		}
	}
};
</script>

<style scoped>
</style>
