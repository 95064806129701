import { render, staticRenderFns } from "./VLoadingOverlay.vue?vue&type=template&id=098ab86d&scoped=true&"
import script from "./VLoadingOverlay.vue?vue&type=script&lang=js&"
export * from "./VLoadingOverlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098ab86d",
  null
  
)

export default component.exports